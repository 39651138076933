import React from 'react';
import styled from 'styled-components';
import Footer from '../footer/footer';

type LayoutProps = {
  children: React.ReactNode
}

const OverflowWrapper = styled.div`
  overflow: hidden;;
`;

const Layout = ({ children }: LayoutProps) => (
  <OverflowWrapper>
    { children }
    <Footer />
  </OverflowWrapper>
);
export default Layout;
