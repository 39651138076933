/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import AOS from 'aos';
import { register } from 'swiper/element/bundle';

type Props = {
    children: React.ReactNode
  }

const LayoutProvider = ({ children }: Props) => {
  useEffect(() => {
    AOS.init();
    register();
  }, []);

  return <>{ children }</>;
};

export default LayoutProvider;
