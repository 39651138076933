export const GRAY = {
  100: '#151515',
  90: '#2C2C2C',
  80: '#444444',
  70: '#5B5B5B',
  60: '#737373',
  50: '#8A8A8A',
  40: '#A1A1A1',
  30: '#B9B9B9',
  20: '#D0D0D0',
  10: '#E8E8E8',
};

export const PURPLE = {
  100: '#990594',
  90: '#A31E9F',
  80: '#AD37A9',
  70: '#B850B4',
  60: '#C269BF',
  50: '#CC82C9',
  40: '#D69BD4',
  30: '#E0B4DF',
  20: '#EBCDEA',
  10: '#F5E6F4',
};

export const CYAN = {
  100: '#07AAC1',
  90: '#20B2C7',
  80: '#39BBCD',
  70: '#51C4D4',
  60: '#6ACCDA',
  50: '#83D4E0',
  40: '#9CDDE6',
  30: '#B5E6EC',
  20: '#CDEEF3',
  10: '#E6F7F9',
};

export const GRADIENT = {
  0: `linear-gradient(0deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  45: `linear-gradient(45deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  90: `linear-gradient(90deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  135: `linear-gradient(135deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  180: `linear-gradient(180deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  225: `linear-gradient(225deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  270: `linear-gradient(270deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  315: `linear-gradient(315deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
  360: `linear-gradient(360deg, ${PURPLE[100]} 5.47%, ${CYAN[100]} 98.18%)`,
};

export const WHITE = '#fff';
export const BLACK = '#000';
