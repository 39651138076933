import React from "react";
import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { H2, P } from "storybook/src/components/Typography";
import styled from "styled-components";
import Corner from "storybook/src/components/Icons/Corner";

const StyledSubscribe = styled.div(
  ({ theme }) => `
  margin-bottom: ${theme.sizes.SPACINGS.XL};
  padding: ${theme.sizes.SPACINGS.XL} ${theme.sizes.SPACINGS.LG} 0;
  color: ${theme.colors.DEFAULT_LIGHT};
  height: 0;
  visibility: hidden;

  ${up("xs")({ theme })}{
    // padding: ${theme.sizes.SPACINGS.XL} ${theme.sizes.SPACINGS.XL} 0;
  }

  ${up("md")({ theme })}{
    visibility: visible;
    padding: ${theme.sizes.SPACINGS.XL} ${theme.sizes.SPACINGS.LG};
    margin-bottom: 0;
    grid-area: b;
    color: ${theme.colors.DEFAULT};
    background: ${theme.colors.DEFAULT_LIGHT};
    position: relative;
    top: -1px;
    height: fit-content;

    :after {
      content: "";
      height: 100%;
      background: inherit;
      right: -100vw;
      top: 0;
      position: absolute;
      width: 100vw;
      z-index: 99999;
    }
  }

  ${up("lg")({ theme })}{
    padding: ${theme.sizes.SPACINGS.XL} ${theme.sizes.SPACINGS.XXL};
  }

  ${up("xl")({ theme })}{
    padding: ${theme.sizes.SPACINGS.XL} ${theme.sizes.SPACINGS.XXXXL};
  }
`
);

const StyledH2 = styled(H2)(
  ({ theme }) => `
  color: inherit;
  margin-bottom: ${theme.sizes.SPACINGS.LG};

  ${up("md")({ theme })}{
    font-size: ${theme.sizes.FONT_SIZES.SMALL};
  }

  ${up("xl")({ theme })}{
    font-size: ${theme.sizes.FONT_SIZES.LARGE};
  }

`
);

const Input = styled.input(
  ({ theme }) => `
  margin-bottom: ${theme.sizes.SPACINGS.LG};
  border-radius: ${theme.sizes.BORDER_RADIUS.SM};
  border: none;
  background: #e6e6e6;
  width: 100%;
  max-width: 458px;
  height: ${theme.sizes.SPACINGS.XXL};
  padding: 0 ${theme.sizes.SPACINGS.LG};
  font-size: ${theme.sizes.FONT_SIZES.XXXS};

  ${up("xl")({ theme })}{
    font-size: ${theme.sizes.FONT_SIZES.XXS};
  }
`
);

const Terms = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.sizes.SPACINGS.XXS};
  align-items: center;
`
);

const Checkbox = styled.input(
  ({ theme }) => `
  width: ${theme.sizes.SPACINGS.SM};
  min-width: ${theme.sizes.SPACINGS.SM};
  height: ${theme.sizes.SPACINGS.SM};
  border: none;
  background: #e6e6e6;
  border-radius: ${theme.sizes.BORDER_RADIUS.SM};
`
);

const StyledP = styled(P)(
  ({ theme }) => `
  color: inherit;
`
);

const TopLeft = styled(Corner)(
  ({ theme }) => `
  position: absolute;
  left: -1000%;

  ${up("md")({ theme })}{
    top: 0;
    left: 1px;
    transform: translateX(-100%) rotate(180deg);
  }
`
);

const BottomLeft = styled(Corner)(
  ({ theme }) => `
  position: absolute;
  left: -1000%;
  fill: ${theme.colors.DEFAULT};
  stroke: ${theme.colors.DEFAULT};

  ${up("md")({ theme })}{
    bottom: -1px;
    left: -1px;
  }
`
);

const StyledPlaceholder = styled.div(
  ({ theme }) => `

    :after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
    }
  `
);

const Subscribe = ({ ...restProps }) => (
  <StyledSubscribe>
    <StyledPlaceholder>
      <StyledH2 size="xxs" {...restProps}>
        SUBSCRIBE
      </StyledH2>
      <Input placeholder="your email" type="email" />
      <Terms>
        <Checkbox type="checkbox" aria-label="agree" />
        <StyledP size="smallest">
          I agree to all your terms and policies
        </StyledP>
      </Terms>
    </StyledPlaceholder>
    <TopLeft size="sm" color="light" />
    <BottomLeft size="sm" color="light" />
  </StyledSubscribe>
);

export default Subscribe;
