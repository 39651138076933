/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import "./reset.css";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../theme/fonts/agrandir/agrandir.css";
import "../theme/fonts/inter/inter.css";
import "../theme/fonts/jakarta/jakarta.css";

type Props = {
  children: React.ReactNode;
};

const GlobalStylesProvider = ({ children }: Props) => <>{children}</>;

export default GlobalStylesProvider;
