import { createTheme } from 'styled-breakpoints';
import { sizes } from './sizes';
import {
  WHITE, BLACK, GRAY, PURPLE, CYAN, GRADIENT,
} from './colors';

export const animation = {
  SLOW: '.4s',
  NORMAL: '.3s',
  FAST: '.2s',
};

export const colors = {
  light: {
    type: 'light',
    DEFAULT: GRAY[100],
    DEFAULT_DARK: BLACK,
    DEFAULT_LIGHT: WHITE,
    DISABLED: GRAY[40],
    MENU: {
      BG: 'rgba(0, 0, 0, 0.9)',
      ACTIVE: GRADIENT[90],
      HOVER: PURPLE[100],
    },
    BUTTON: {
      BG: BLACK,
    },
    HERO: {
      BG: BLACK,
      BG_SHADOW: `${PURPLE[100]}50`,
      CARD_BG: GRAY[100],
      CARD_SHADOW: GRAY[90],
    },
    GRADIENT: {
      TEXT: GRADIENT[90],
      BG: GRADIENT[90],
      180: GRADIENT[180],
    },
    TEXT: {
      DEFAULT: GRAY[100],
      DEFAULT_LIGHT: GRAY[70],
      LIGHT: WHITE,
    },
    FILTER: {
      DROP_SHADOW: 'drop-shadow(0px 3.81145px 160.04px rgba(209, 209, 209, 0.6))',
    },
  },

  dark: {
    type: 'dark',
    DEFAULT: GRAY[100],
    DEFAULT_DARK: BLACK,
    DEFAULT_LIGHT: WHITE,
    DISABLED: GRAY[40],
    MENU: {
      BG: 'rgba(0, 0, 0, 0.9)',
      ACTIVE: GRADIENT[90],
      HOVER: PURPLE[100],
    },
    BUTTON: {
      BG: BLACK,
    },
    HERO: {
      BG: BLACK,
      BG_SHADOW: `${PURPLE[100]}50`,
      CARD_BG: GRAY[100],
      CARD_SHADOW: GRAY[90],
    },
    GRADIENT: {
      TEXT: GRADIENT[90],
      BG: GRADIENT[90],
      180: GRADIENT[180],
    },
    TEXT: {
      DEFAULT: GRAY[100],
      DEFAULT_LIGHT: GRAY[70],
      LIGHT: WHITE,
    },
    FILTER: {
      DROP_SHADOW: 'drop-shadow(0px 3.81145px 160.04px rgba(209, 209, 209, 0.6))',
    },
  },
};

const customStyledBreakpoints = createTheme({
  xxxs: '320px',
  xxs: '375px',
  xs: '425px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
} as const);

export const defaultTheme = {
  colors: colors.light || colors.dark,
  sizes,
  animation,
  ...customStyledBreakpoints,
};

export type HyperDexTheme = typeof defaultTheme;
