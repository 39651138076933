import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import {
  FONT_SIZE_MODIFIERS, FONT_WEIGHT_MODIFIERS, COLOR_MODIFIERS, FONT_FAMILY_MODIFIERS,
} from '../../theme/styleModifiers';
import { HProps } from './types/HProps';

const StyledH1 = styled.h1<HProps<'h1'>>`
  ${applyStyleModifiers(FONT_SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FONT_WEIGHT_MODIFIERS, 'weight')};
  ${applyStyleModifiers(FONT_FAMILY_MODIFIERS, 'font')};
  ${applyStyleModifiers(COLOR_MODIFIERS, 'color')};
  line-height: 1.5em;
`;
const StyledH2 = styled.h2<HProps<'h2'>>`
  ${applyStyleModifiers(FONT_SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FONT_WEIGHT_MODIFIERS, 'weight')};
  ${applyStyleModifiers(FONT_FAMILY_MODIFIERS, 'font')};
  ${applyStyleModifiers(COLOR_MODIFIERS, 'color')};
  line-height: 1.5em; 
`;
const StyledH3 = styled.h3<HProps<'h3'>>`
  ${applyStyleModifiers(FONT_SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FONT_WEIGHT_MODIFIERS, 'weight')};
  ${applyStyleModifiers(FONT_FAMILY_MODIFIERS, 'font')};
  ${applyStyleModifiers(COLOR_MODIFIERS, 'color')};
  line-height: 1.5em;
`;
const StyledH4 = styled.h4<HProps<'h4'>>`
  ${applyStyleModifiers(FONT_SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FONT_WEIGHT_MODIFIERS, 'weight')};
  ${applyStyleModifiers(FONT_FAMILY_MODIFIERS, 'font')};
  ${applyStyleModifiers(COLOR_MODIFIERS, 'color')};
  line-height: 1.5em;
`;

export const H1 = ({
  children, size = 'large', weight = '800', font = 'agrandir', color = 'default', ...rest
}: HProps<'h1'>) => (
  <StyledH1 size={size} weight={weight} color={color} font={font} {...rest}>
    {children}
  </StyledH1>
);

export const H2 = ({
  children, size = 'xl', weight = '800', font = 'agrandir', color = 'default', ...rest
}: HProps<'h2'>) => (
  <StyledH2 size={size} weight={weight} color={color} font={font} {...rest}>
    {children}
  </StyledH2>
);

export const H3 = ({
  children, size = 'small', weight = '800', font = 'agrandir', color = 'default', ...rest
}: HProps<'h3'>) => (
  <StyledH3 size={size} weight={weight} color={color} font={font} {...rest}>
    {children}
  </StyledH3>
);

export const H4 = ({
  children, size = 'xxs', weight = '800', font = 'agrandir', color = 'default', ...rest
}: HProps<'h4'>) => (
  <StyledH4 size={size} weight={weight} color={color} font={font} {...rest}>
    {children}
  </StyledH4>
);
