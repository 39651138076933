import React from 'react';
import Svg, { SvgPropTypes } from './Svg';

const Corner = ({
  size, color, shadow, ...restprops
}: SvgPropTypes) => (
  <Svg size={size} shadow={shadow} color={color} {...restprops} viewBox="0 0 46 46">
    <path d="M-1.79827e-06 2.46825e-06C-1.79827e-06 25.6255 21.2152 46 46 46L-1.79827e-06 46L-1.79827e-06 2.46825e-06Z" />
  </Svg>
);

export default Corner;
