import React from 'react';
import Svg, { SvgPropTypes } from './Svg';

const Instagram = ({
  size, color, shadow, ...restProps
}: SvgPropTypes) => (
  <Svg size={size} shadow={shadow} color={color} viewBox="0 0 24 24" {...restProps}>
    <path d="M16.7579 19.1176H7.35971C6.061 19.1176 5 18.0567 5 16.7579V7.35971C5 6.061 6.061 5 7.35971 5H16.7579C18.0566 5 19.1176 6.061 19.1176 7.35971V16.7579C19.1176 18.0624 18.0624 19.1176 16.7579 19.1176Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.0866 7.62333C17.0866 8.00739 16.7753 8.31874 16.3912 8.31874C16.0071 8.31874 15.6958 8.00739 15.6958 7.62333C15.6958 7.23926 16.0071 6.92792 16.3912 6.92792C16.7753 6.92792 17.0866 7.23926 17.0866 7.62333ZM9.49923 14.6237C10.1834 15.3078 11.0936 15.6847 12.0619 15.6847C13.0301 15.6847 13.9345 15.3078 14.6245 14.6237C15.3086 13.9395 15.6855 13.0293 15.6855 12.061C15.6855 11.0928 15.3086 10.1826 14.6245 9.49844C13.9403 8.8143 13.0301 8.43744 12.0619 8.43744C11.0936 8.43744 10.1834 8.8143 9.49923 9.49844C8.81509 10.1826 8.43823 11.0928 8.43823 12.061C8.43823 13.0293 8.81509 13.9395 9.49923 14.6237ZM9.20934 12.061C9.20934 10.484 10.4907 9.20855 12.0619 9.20855C13.6331 9.20855 14.9086 10.4898 14.9144 12.061C14.9144 13.6381 13.6331 14.9136 12.0619 14.9136C10.4849 14.9136 9.20934 13.6323 9.20934 12.061Z" fill="white" />
  </Svg>
);

export default Instagram;
