import React from "react";
import { up } from "styled-breakpoints";
import { Text } from "storybook/src/components/Typography";
import styled from "styled-components";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { nanoid } from "nanoid";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const StyledLinks = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.sizes.SPACINGS.LG};
  padding: 0 ${theme.sizes.SPACINGS.LG};
  cursor: pointer;

  ${up("xs")({ theme })}{
    padding: 0 ${theme.sizes.SPACINGS.XL};
  }

  ${up("md")({ theme })}{
    width: 50%;
    padding: 0; 
    margin-bottom: 0;
    gap: ${theme.sizes.SPACINGS.XXS};
    align-items: center;
  }

  ${up("lg")({ theme })}{
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, ${theme.sizes.SPACINGS.XL});
    grid-auto-flow: column;
  }
`
);

const NavLink = styled(Text).attrs({ forwardedAs: "li" })(
  ({ theme }) => `
    list-style: none;

    :hover,
    focus {
      color: ${theme.colors.MENU.HOVER};
    };

    ${up("md")({ theme })}{
        min-width: 110px;
    }

  }
`
);

const Links = ({ ...restProps }) => {
  const isUpMd = useBreakpoint(up("md"));

  const links = [
    { text: "About", location: "/" },
    {
      text: "Join Presale",
      location: "https://t.me/HyperDexFinance",
      external: true,
    },
    /*{
      text: "Platform Stats",
      location: isUpMd ? "#platform-stats" : "#header",
    },*/
    { text: "How It Works", location: "#how" },
    { text: "Cubes", location: "#platform" },
    { text: "Team", location: "#team" },
    { text: "Roadmap", location: "#roadmap" },
    // { text: "FAQ", location: "#" },
    // { text: "Contact", location: "#" },
  ];

  const navLinks = links.map(({ text, location, external }) => (
    <NavLink key={nanoid()} color="light" size="xxxs">
      {external ? (
        <a href={location} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ) : (
        <AnchorLink to={location}>{text}</AnchorLink>
      )}
    </NavLink>
  ));
  return <StyledLinks {...restProps}>{navLinks}</StyledLinks>;
};

export default Links;
