import React from "react";
import { up } from "styled-breakpoints";
import { P } from "storybook/src/components/Typography";
import { Link } from "gatsby";
import Logo from "storybook/src/components/Icons/Logo";
import styled from "styled-components";
import IconButton from "storybook/src/components/Button/IconButton";
import Facebook from "storybook/src/components/Icons/Facebook";
import Instagram from "storybook/src/components/Icons/Instagram";
import Youtube from "storybook/src/components/Icons/Youtube";
import TwitterX from "storybook/src/components/Icons/TwitterX";
import Telegram from "storybook/src/components/Icons/Telegram";

const StyledDescription = styled.div(
  ({ theme }) => `
  margin-bottom: ${theme.sizes.SPACINGS.XL};
  padding: 0 ${theme.sizes.SPACINGS.LG};

  ${up("xs")({ theme })}{
    padding: 0 ${theme.sizes.SPACINGS.XL};
  }

  ${up("md")({ theme })}{
    width: 50%;
    padding: 0 0 0 ${theme.sizes.SPACINGS.XL};
    margin-bottom: 0;
    max-width: 30ch;
  }

  ${up("lg")({ theme })}{
    padding: 0 0 0 ${theme.sizes.SPACINGS.XXL};
  }

  ${up("xl")({ theme })}{
    padding: 0 0 0 ${theme.sizes.SPACINGS.XXXXL};
  }

`
);

const LogoWrapper = styled.div(
  ({ theme }) => `
  font-size: ${theme.sizes.FONT_SIZES.LARGE};
  margin-bottom: ${theme.sizes.SPACINGS.LG};
`
);

const Contacts = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.sizes.SPACINGS.SM};
`
);

const StyledP = styled(P)(
  ({ theme }) => `
    margin-bottom: ${theme.sizes.SPACINGS.XL};
`
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
    border-radius: 50%;
    background-color: ${theme.colors.DEFAULT_LIGHT};
    height: 40px;
    width: 40px;
    display: grid;
    place-content: center;
`
);

const Description = ({ ...restProps }) => (
  <StyledDescription>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    <StyledP color="light" size="xxxs">
      HyperDex is an all-in-one investment platform built for everybody.
    </StyledP>
    <Contacts>
      <a
        href="https://t.me/HyperDexFinance"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledIconButton aria-label="Telegram">
          <Telegram size="xs" />
        </StyledIconButton>
      </a>
      <a
        href="https://twitter.com/HyperdexFinance"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledIconButton aria-label="X">
          <TwitterX size="xs" />
        </StyledIconButton>
      </a>
      <a
        href="https://www.instagram.com/hyperdexfinance/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledIconButton aria-label="Instagram">
          <Instagram size="xs" />
        </StyledIconButton>
      </a>
    </Contacts>
  </StyledDescription>
);

export default Description;
