import React from "react";
import styled from "styled-components";
import { up } from "styled-breakpoints";
import { P, Text } from "storybook/src/components/Typography";
import { nanoid } from "nanoid";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Subscribe from "./subscribe";
import Description from "./description";
import Links from "./links";

const StyledFooter = styled.footer(
  ({ theme }) => `
  background: ${theme.colors.DEFAULT};

  ${up("md")({ theme })}{
    margin: ${theme.sizes.SPACINGS.XL} 0 0;
  }  
  ${up("lg")({ theme })}{
    margin: ${theme.sizes.SPACINGS.XXL} 0 0;
  }  
  ${up("xl")({ theme })}{
    margin: ${theme.sizes.SPACINGS.XXXXL} 0 0;
  }
`
);

const Container = styled.div(
  ({ theme }) => `
  max-width: ${theme.sizes.MAX_PAGE_WIDTH};
  margin: 0 auto;  

  ${up("md")({ theme })}{
      display: grid;
      grid-template-areas: 
          "a b";
      grid-template-rows: 60% 40%;
  }
`
);

const Terms = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  text-align: center;  
  
  ${up("md")({ theme })}{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${theme.sizes.SPACINGS.LG}; 
  }
`
);

const Wrapper = styled.div(
  ({ theme }) => `
    ${up("md")({ theme })}{
        grid-area: a;
        display: flex;
        justify-content: space-around;
        padding: ${theme.sizes.SPACINGS.XL} 0;
    }

    ${up("lg")({ theme })}{
        justify-content: space-between;
    }
`
);

const BottomWrapper = styled.div(
  ({ theme }) => `
    background: ${theme.colors.GRADIENT.BG};
`
);

const Bottom = styled.div(
  ({ theme }) => `
    max-width: ${theme.sizes.MAX_PAGE_WIDTH};
    padding: ${theme.sizes.SPACINGS.LG} ${theme.sizes.SPACINGS.LG};

    ${up("xs")({ theme })}{
        padding: ${theme.sizes.SPACINGS.XS} ${theme.sizes.SPACINGS.XL};
    }

    ${up("md")({ theme })}{
        display: flex;
        justify-content: space-between;
        grid-area: c;
        margin: 0 auto;
    }

    ${up("lg")({ theme })}{
        padding: ${theme.sizes.SPACINGS.XS} ${theme.sizes.SPACINGS.XXL};
    }

    ${up("xl")({ theme })}{
        padding: ${theme.sizes.SPACINGS.XS} ${theme.sizes.SPACINGS.XXXXL};
    }
`
);

const NavLink = styled(Text).attrs({ forwardedAs: "li" })(
  ({ theme }) => `
    list-style: none;
  }
`
);

const StyledP = styled(P)(
  ({ theme }) => `
    text-align: center;
`
);

const Footer = ({ ...restProps }) => {
  const links = [
    { text: "Terms & Conditions", location: "#" },
    { text: "Privacy Policy", location: "#" },
  ];

  const terms = links.map(({ text, location }) => (
    <NavLink key={nanoid()} color="light" size="xxxxs">
      <AnchorLink to={location}>{text}</AnchorLink>
    </NavLink>
  ));

  return (
    <StyledFooter {...restProps}>
      <Container>
        <Subscribe />
        <Wrapper>
          <Description />
          <Links />
        </Wrapper>
      </Container>
      <BottomWrapper>
        <Bottom>
          <StyledP size="xxxxs" color="light">
            &#169; HYPERDEX 2023 - all rights reserved
          </StyledP>
          {/*<Terms>
            {terms}
          </Terms>*/}
        </Bottom>
      </BottomWrapper>
    </StyledFooter>
  );
};

export default Footer;
