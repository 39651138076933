import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { Modifier } from '../../theme/styleModifiers';

export type SizeVariants = 'auto' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ColorVariants = 'default' | 'light';

export const FILL_MODIFIERS: Modifier<ColorVariants> = {
  default: ({ theme }) => `
    fill: ${theme.colors.GRADIENT};
  `,
  light: ({ theme }) => `
    fill: ${theme.colors.DEFAULT_LIGHT};
  `,
};

export const STROKE_MODIFIERS: Modifier<ColorVariants> = {
  default: ({ theme }) => `
    stroke: ${theme.colors.GRADIENT};
  `,
  light: ({ theme }) => `
    stroke: ${theme.colors.DEFAULT_LIGHT};
  `,
};

export const SIZE_MODIFIERS: Modifier<SizeVariants> = {
  auto: ({ theme }) => `
    height: ${theme.sizes.ICON_SIZES.AUTO};
  `,
  xs: ({ theme }) => `
    height: ${theme.sizes.ICON_SIZES.XS};
  `,
  sm: ({ theme }) => `
    height: ${theme.sizes.ICON_SIZES.SM};
  `,
  md: ({ theme }) => `
    height: ${theme.sizes.ICON_SIZES.MD};
  `,
  lg: ({ theme }) => `
    height: ${theme.sizes.ICON_SIZES.LG};
  `,
  xl: ({ theme }) => `
    height: ${theme.sizes.ICON_SIZES.XL};
  `,
};

export type SvgPropTypes = {
  size?: SizeVariants,
  stroke?: ColorVariants,
  color?: ColorVariants,
  shadow?: boolean,
};

const Svg = styled.svg<SvgPropTypes>` 
  ${applyStyleModifiers(SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FILL_MODIFIERS, 'color')};
  ${applyStyleModifiers(STROKE_MODIFIERS, 'color')};
  filter: ${(({ shadow }) => (shadow ? 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))' : 'none'))};
`;

Svg.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  size: 'md',
  color: 'default',
  shadow: false,
};

export default Svg;
