import { HyperDexTheme } from '.';

export type Modifier<T extends string> = Record<T, (props: { theme: HyperDexTheme }) => string>;

export type FontSizeVariants = 'extra'| 'xxxxl'| 'xxxl'| 'xxl'| 'xl'| 'large'| 'medium'| 'small'| 'xs'| 'xxs'| 'xxxs'| 'xxxxs'| 'smallest';
export type FontWeightVariants = '800' | '600' | '400';
export type FontFamilyVariants = 'agrandir' | 'inter' | 'jakarta';
export type ColorVariants = 'default' | 'default_light' | 'light' | 'gradient';

const FONT_SIZE_MODIFIERS: Modifier<FontSizeVariants> = {
  extra: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.EXTRA};
    line-height: 1.75em;
  `,
  xxxxl: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XXXXL};
    line-height: 1.75em;
  `,
  xxxl: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XXXL};
    line-height: 1.75em;
  `,
  xxl: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XXL};
    line-height: 1.75em;
  `,
  xl: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XL};
    line-height: 1.75em;
  `,
  large: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.LARGE};
    line-height: 1.75em;
  `,
  medium: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.MEDIUM};
    line-height: 1.75em;
  `,
  small: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.SMALL};
    line-height: 1.75em;
  `,
  xs: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XS};
    line-height: 1.75em;
  `,
  xxs: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XXS};
    line-height: 1.75em;
  `,
  xxxs: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XXXS};
    line-height: 1.75em;
  `,
  xxxxs: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.XXXXS};
    line-height: 1.75em;
  `,
  smallest: ({ theme }) => `
    font-size: ${theme.sizes.FONT_SIZES.SMALLEST};
    line-height: 1.75em;
  `,
};

const FONT_WEIGHT_MODIFIERS: Modifier<FontWeightVariants> = {
  400: ({ theme }) => `
    font-weight: ${theme.sizes.FONT_WEIGHTS[400]};
  `,
  600: ({ theme }) => `
    font-weight: ${theme.sizes.FONT_WEIGHTS[600]};
  `,
  800: ({ theme }) => `
    font-weight: ${theme.sizes.FONT_WEIGHTS[800]};
  `,
};

const COLOR_MODIFIERS: Modifier<ColorVariants> = {
  default: ({ theme }) => `
    color: ${theme.colors.TEXT.DEFAULT};
  `,
  default_light: ({ theme }) => `
    color: ${theme.colors.TEXT.DEFAULT_LIGHT};
  `,
  light: ({ theme }) => `
    color: ${theme.colors.TEXT.LIGHT};
  `,
  gradient: ({ theme }) => `
    background: ${theme.colors.GRADIENT.TEXT};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: fit-content;
  `,
};

const FONT_FAMILY_MODIFIERS: Modifier<FontFamilyVariants> = {
  inter: () => 'font-family: \'Inter\'',
  agrandir: () => 'font-family: \'Agrandir\'',
  jakarta: () => 'font-family: \'Jakarta\'',
};

export {
  FONT_SIZE_MODIFIERS,
  FONT_WEIGHT_MODIFIERS,
  FONT_FAMILY_MODIFIERS,
  COLOR_MODIFIERS,
};
