import React, { useEffect, useState, createContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, colors } from '../../theme';

type ThemeProps = { children: React.ReactNode };

export const ThemeContext = createContext(() => {});

const Theme = ({ children }: ThemeProps) => {
  const { light, dark } = colors;

  const lightTheme = {
    ...defaultTheme,
    colors: light,
  };

  const darkTheme = {
    ...defaultTheme,
    colors: dark,
  };

  const inMemoryTheme = typeof window !== 'undefined' && localStorage.getItem('theme') === 'dark' ? darkTheme : lightTheme;

  const [theme, setTheme] = useState(inMemoryTheme || lightTheme);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme.colors.type === 'light' ? darkTheme : lightTheme));
  };

  useEffect(() => {
    localStorage.setItem('theme', theme.colors.type);
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={toggleTheme}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export default Theme;
