export const FONT_SIZES = { // consider rems
  EXTRA: '96px',
  XXXXL: '80px',
  XXXL: '64px',
  XXL: '56px',
  XL: '44px',
  LARGE: '32px',
  MEDIUM: '28px',
  SMALL: '24px',
  XS: '20px',
  XXS: '18px',
  XXXS: '16px',
  XXXXS: '14px',
  SMALLEST: '12px',
};

export const FONT_WEIGHTS = {
  400: '400',
  600: '600',
  800: '800',
};

export const ICON_SIZES = {
  AUTO: '1em',
  XS: '24px',
  SM: '40px',
  MD: '56px',
  LG: '96px',
  XL: '152px',
};

export const SPACINGS = {
  XXXXL: '88px',
  XXXL: '70px',
  XXL: '54px',
  XL: '44px',
  LG: '24px',
  MD: '20px',
  SM: '16px',
  XS: '12px',
  XXS: '8px',
  XXXS: '4px',
};

export const BUTTON_HEIGHT = {
  MD: '54px',
};

export const BORDER_RADIUS = {
  SM: '12px',
  MD: '20px',
};

export const MAX_PAGE_WIDTH = '1300px';

export const sizes = {
  FONT_SIZES,
  FONT_WEIGHTS,
  ICON_SIZES,
  SPACINGS,
  BUTTON_HEIGHT,
  BORDER_RADIUS,
  MAX_PAGE_WIDTH,
};
