import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import {
  FONT_SIZE_MODIFIERS, FONT_WEIGHT_MODIFIERS, COLOR_MODIFIERS,
  FontSizeVariants, FontWeightVariants, ColorVariants, FontFamilyVariants,
} from '../../theme/styleModifiers';

export type GenericTextProps = {
  as?: React.ElementType,
  forwardedAs?: React.ElementType,
  size?: FontSizeVariants,
  font?: FontFamilyVariants,
  weight?: FontWeightVariants,
  color?: ColorVariants,
    } & React.ComponentPropsWithoutRef<'div'>;

const StyledText = styled.div<GenericTextProps>`
  ${applyStyleModifiers(FONT_SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FONT_WEIGHT_MODIFIERS, 'weight')};
  ${applyStyleModifiers(COLOR_MODIFIERS, 'color')};
  font-family: 'Inter';
`;

const Text = ({
  children, as, forwardedAs, size = 'xxs', weight = '400', font = 'inter', color = 'default', ...rest
}: GenericTextProps) => (
  <StyledText
    as={as}
    forwardedAs={forwardedAs}
    size={size}
    weight={weight}
    font={font}
    color={color}
    {...rest}
  >
    {children}
  </StyledText>
);

export default Text;
