import React from 'react';
import styled from 'styled-components';

type IconButtonProps = {
  children: React.ReactNode,
  onClick?: () => void,
  disabled?: boolean,
  type?: 'button' | 'reset' | 'submit',
} & React.ComponentPropsWithoutRef<'button'>;

const StyledButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;

  :hover,
  :focus {
      opacity: .8;
  }
  :disabled {
    cursor: initial;
  }
`;

const IconButton = ({
  onClick, disabled, children, type = 'button', ...restProps
}: IconButtonProps) => (
  <StyledButton disabled={disabled} onClick={onClick} type={type} {...restProps}>
    {children}
  </StyledButton>
);

export default IconButton;
