import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import ThemeProvider from 'storybook/src/components/ThemeProvider/Theme';
import GlobalStylesProvider from 'storybook/src/providers/global-styles';
import ThirdPartyProvider from './src/providers/third-party';
import Layout from './src/components/layout/layout';

type WrapPageElement = GatsbyBrowser['wrapPageElement'];
type WrapRootElement = GatsbyBrowser['wrapRootElement'];

export const wrapPageElement: WrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const wrapRootElement: WrapRootElement = ({ element }) => (
  <ThemeProvider>
    <GlobalStylesProvider>
      <ThirdPartyProvider>
        {element}
      </ThirdPartyProvider>
    </GlobalStylesProvider>
  </ThemeProvider>
);
