import React from 'react';
import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import {
  FONT_SIZE_MODIFIERS, FONT_WEIGHT_MODIFIERS, COLOR_MODIFIERS,
  FontSizeVariants, FontWeightVariants, ColorVariants, FONT_FAMILY_MODIFIERS, FontFamilyVariants,
} from '../../theme/styleModifiers';

export type PProps = {
  size?: FontSizeVariants,
  weight?: FontWeightVariants,
  font?: FontFamilyVariants,
  color?: ColorVariants,
    } & React.ComponentPropsWithoutRef<'p'>;

const StyledP = styled.p<PProps>`
  ${applyStyleModifiers(FONT_SIZE_MODIFIERS, 'size')};
  ${applyStyleModifiers(FONT_WEIGHT_MODIFIERS, 'weight')};
  ${applyStyleModifiers(FONT_FAMILY_MODIFIERS, 'font')};
  ${applyStyleModifiers(COLOR_MODIFIERS, 'color')};
`;

const P = ({
  children, size = 'xxxs', weight = '400', font = 'inter', color = 'default', ...rest
}: PProps) => (
  <StyledP size={size} weight={weight} font={font} color={color} {...rest}>
    {children}
  </StyledP>
);

export default P;
